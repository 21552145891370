import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";

import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { SDKContext } from "fanfire-components";
import { networkMap } from "./helpers/metamaskNetworks";

import { AppConfigContext, initFirebase } from "fanfire-components";
const Firebase = initFirebase(
  process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV ?? "staging"
);

import { SliceStore } from "fanfire-components";
import { SnackBarProvider } from "fanfire-components";
import { getAuth, onAuthStateChanged } from "fanfire-components";
import { FAQ } from "fanfire-components";
import { DesktopFooter } from "fanfire-components";
import { Landing } from "fanfire-components";
import { Explore } from "fanfire-components";
import { ForSale } from "fanfire-components";
import { NavBar } from "fanfire-components";
import { Profile } from "fanfire-components";
import { NFTDetail } from "fanfire-components";
import { FooterMobile } from "fanfire-components";
import { AttendanceTokens } from "fanfire-components";
import { ListCollectionNFTs } from "fanfire-components";
import { Text } from "fanfire-components";

import { ThemeProvider, Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./app-Config-Styles";

import appConfig from "./app-config";
import { faqs } from "./app-config";

const { ethereum } = window;
if (ethereum) {
  var provider = new ethers.providers.Web3Provider(ethereum);
}

const firebaseAuth = getAuth();

const styles = {
  app: {
    minHeight: "100vh",
    maxWidth: "100vw",
    overflow: "auto",
    position: "relative" as "relative",
  },
  content: {
    paddingBottom: "310px",
  },
};

function App() {
  const context = useContext(SDKContext);
  const [authLoading, setAuthLoading] = useState(true);
  const [state, setState] = useState({ mobileView: false });
  const [chainWarning, setChainWarning] = useState(false);

  // const { showSnackBar } = useSnackBar();
  const { mobileView } = state;
  const dispatch = useDispatch();

  const ffsdk = context.ffsdk;
  ffsdk?.marketplace.init(
    process.env.REACT_APP_FANFIRE_MARKETPLACE_CONTRACT_ADDRESS ??
      "0xfcA0F467955e88FBC2054B7F22ca033c8A6ADDd3"
  );

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  // Persist Firbase Auth if so, persist auth
  const onAuthStateChanger = async (firebaseUser: any) => {
    try {
      if (firebaseUser?.email && firebaseUser?.emailVerified) {
        setAuthLoading(true);
        console.log(
          "User has Connected FireBase Auth: Persisting Auth to app.."
        );
        await dispatch(SliceStore.persistFireBaseAuth({ ffsdk: ffsdk }));
        setAuthLoading(false);
      } else {
        console.log("No Firebase auth Connection");
      }
      setAuthLoading(false);
    } catch (error) {
      // showSnackBar('Error persisting google Auth', 'error', error);
      console.error(error);
    }
  };

  const checkChain = async (chainIdChangedTo: any) => {
    const chain = process.env.REACT_APP_FANFIRE_CHAIN ?? "mumbai";
    const chainId = chain === "mumbai" ? 80001 : 137;
    try {
      if (chainIdChangedTo !== ethers.utils.hexValue(chainId)) {
        setChainWarning(true);
        console.log("Switching Network to: " + chain + `(${chainId})`);
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: ethers.utils.hexValue(chainId) }], // chainId must be in hexadecimal numbers
        });
        console.log("done");
        setChainWarning(false);
        window.location.reload();
      } else {
        console.log("User Already on right chain: " + chain + `(${chainId})`);
      }
    } catch (error) {
      if (error.code === 4902) {
        // chain not added on metamask
        console.log("chain not added to metamask");
        try {
          console.log("Attemting to add network");
          setChainWarning(true);
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [networkMap[chain]],
          });
          console.log("Added");
          console.log("Attemting to switch to chain");
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexValue(chainId) }], // chainId must be in hexadecimal numbers
          });
          console.log("done");
          window.location.reload();
          setChainWarning(false);
        } catch (addError) {
          console.error(addError);
        }
      }
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (chainId: any) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        // window.location.reload(); // Done in checkChain()
        console.log("chain Changed! : " + chainId);
        checkChain(chainId);
      });
    }
  }, []);

  // Check if there is any connected metamask account
  const isMetaMaskConnected = async () => {
    try {
      if (!window.ethereum) {
        console.log("No Metamask to access. Skipping..");
        return false;
      } else {
        const accounts = await provider.listAccounts();
        return accounts.length > 0;
      }
    } catch (err) {
      console.error(err);
      // showSnackBar('Error checking for MetaMask Accounts', 'error', err);
    }
  };

  // Log in user to metamask
  const logInMetaMask = async () => {
    try {
      setAuthLoading(true);
      const signer: any = await dispatch(SliceStore.signInWithMetaMask());
      await ffsdk?.enableWeb3(signer.payload.user, ffsdk.fanFireAPI);
      console.log("Authenticated: ", signer.payload.user);
      setAuthLoading(false);
      return signer;
    } catch (error) {
      console.error(error);
      // showSnackBar('Error Persisting Metamask auth', 'error', error);
    }
  };

  // Manage Log In
  // If user is connected on MM to site - the app will connect to MM
  // If no MM auth is seen - it checkes for Firebase auth and connects if true
  // Basically: MM connected extensions get preference.
  // A user has to maually sign out of MM
  useEffect(() => {
    setAuthLoading(true);
    const prep = async () => {
      await isMetaMaskConnected().then((connected) => {
        if (connected) {
          try {
            console.log(
              "User has a Connected MetaMask account to site: Connecting to app.."
            );
            // dispatch(SliceStore.signOutWithGoogle());
            const signer = logInMetaMask();
            return signer;
          } catch (error) {
            console.error(error);
            // showSnackBar('Error Persisting MetaMask Auth', 'error', error)
          }
        } else if (!connected) {
          try {
            console.log("MetaMask is not currently connected to this site");
            console.log("Checking Firebase Auth..");
            const subscriber = onAuthStateChanged(
              firebaseAuth,
              onAuthStateChanger
            );
            return subscriber;
          } catch (error) {
            // showSnackBar('Error Checking / Persisting Google log in', 'error', error)
          }
        } else {
          console.log("No Auth method active");
        }
      });
    };
    prep();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App" style={styles.app}>
        <SnackBarProvider>
          <SDKContext.Provider value={{ ffsdk }}>
            <AppConfigContext.Provider value={{ appConfig }}>
              <Router>
                {!window.location.pathname.includes("/attendanceTokens") && (
                  <NavBar
                    authLoading={authLoading}
                    mobileView={mobileView}
                    sdkService={ffsdk}
                    appConfig={appConfig}
                    sliceStore={SliceStore}
                  />
                )}
                {chainWarning && (
                  <Box
                    pt={10}
                    style={{
                      zIndex: "1099",
                      position: "relative",
                      width: "100%",
                      pointerEvents: "none",
                      background: "black",
                    }}
                  >
                    <Text
                      variantText="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >{`You need to be on the ${process.env.REACT_APP_FANFIRE_CHAIN} chain to be able to use this site with web3`}</Text>
                    <Text
                      variantText="body1"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      Please check your metamask extension to change to the
                      correct chain.
                    </Text>
                  </Box>
                )}
                {appConfig.general.sdkService === "demo" && (
                  <Box pt={10}>
                    <Text variantText="body2">Using Dummy Service</Text>
                  </Box>
                )}
                <div style={styles.content}>
                  <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route
                      path="explore"
                      element={
                        <Explore sdkService={ffsdk} appConfig={appConfig} />
                      }
                    />
                    <Route
                      path="for-sale"
                      element={
                        <ForSale sdkService={ffsdk} appConfig={appConfig} />
                      }
                    />
                    <Route path="faq" element={<FAQ faqItems={faqs} />} />
                    <Route
                      path=":address/:id"
                      element={
                        <NFTDetail
                          mobileView={mobileView}
                          authLoading={authLoading}
                          sdkService={ffsdk}
                          appConfig={appConfig}
                          sliceStore={SliceStore}
                        />
                      }
                    />
                    <Route
                      path="profile"
                      element={
                        <Profile
                          authLoading={authLoading}
                          sdkService={ffsdk}
                          appConfig={appConfig}
                          sliceStore={SliceStore}
                        />
                      }
                    />
                    <Route
                      path="attendanceTokens"
                      element={
                        <AttendanceTokens
                          authLoading={authLoading}
                          appConfig={appConfig}
                          sdkService={ffsdk}
                          sliceStore={SliceStore}
                        />
                      }
                    />
                    <Route
                      path="nftCollection/:address/:id"
                      element={
                        <ListCollectionNFTs
                          sdkService={ffsdk}
                          appConfig={appConfig}
                        />
                      }
                    />
                  </Routes>
                </div>
                {mobileView ? (
                  <FooterMobile appConfig={appConfig} />
                ) : (
                  !window.location.pathname.includes("/attendanceTokens") && (
                    <DesktopFooter appConfig={appConfig} sdkService={ffsdk} />
                  )
                )}
              </Router>
            </AppConfigContext.Provider>
          </SDKContext.Provider>
        </SnackBarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
