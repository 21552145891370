/* 
=================================================================================================================
=================================================================================================================
=== APP CONFIG ==================================================================================================
=================================================================================================================
=================================================================================================================

Please populate the below to make changes to the app.
Please do not remove any fields. 
*/


const appConfig = {
  /* 
  =================================================================================================================
  === GENERAL CONFIGS =============================================================================================
  =================================================================================================================
  */
  general: {
    app:'Strijdom',
    appCategory: 'art',
    sdkService: 'fanFireSDK', // fanFireSDK | demo 
    logo: require('./assets/strijdom_logo.png'), // Logo on the Nav bar
    contactDetails: {
      telephone: '021 833 9652',
      email: 'strijdom@fanfire.ai'
    },
    socialMedia: {
      // add more possibilities for users
      instagram: 'https://www.instagram.com/the52collection/',
      twitter: 'https://twitter.com/the52collection',
      facebook: 'https://www.facebook.com/the52collection/',
      youtube: 'https://www.youtube.com/channel/UCZHJkIydohmuTA-hHGdHJUw',
      custom: [ // TODO: support SVG
        { //Fanfire
          logo: require('./assets/FanFire_Logo_white.png'), //change to either black or white
          link: 'https://linktr.ee/fanfire'
        },
        // Add more objects here to add more options
      ]
    },
    // some general throughout configs
    disablePurchasing: false, // Disbles purchasing / listing / unlisting
    openseaLogo: require('./assets/OpenSeaBlue.png'), //Logo to use for Opensea (does not support svg yet)
    metaMaskLogo: require('./assets/MetaMask-Fox.png'), //Logo to use for Metamask
    listViewsErrorImage: require('./assets/placeholderImage.png'), // Image used on error pages when trying to list NFTs
    nftImageNotFoundImage: require('./assets/placeholderImage.png'), // Image to use on card item if image of NFT can not be found
    cardItemViewText: 'View Artwork', // Text displayed on card item to route into detail view
    serviceFee: 2.5, //Amount for the service
    creatorFee: 10, //Amount the creator gets

    site_noun: 'artwork',
    site_noun_plural: 'artworks',

    rightsText: 'Commercial Rights', //Text used for rights text on the checkout modal when buying an item
    rightsAgreeButtonText: "I understand that I am purchasing the commercial rights for this artwork.", // Text used for specifying the sentence to use where the user has to agree when buying an item

    //Some General style overides in the app
    styleOverrides: {
      buttonPrimaryTextColor: {
        color: 'black'
      }
    }
  },
  /* 
  =================================================================================================================
  === LADNING PAGE ================================================================================================
  =================================================================================================================
  */
  landingPage: {
    // Header on the Landing Page
    header: "THE FIFTY TWO COLLECTION", 

    // Description on the Landing Page
    description: "Land artist Strijdom van der Merwe announced the '52 Collection' where for 52 weeks he will create a new land art installation. Strijdom partnered with technology firm Fanfire to capture permanent digital originals of his fleeting artworks.",

    // This is the main image displayed on the Landing page
    landingImagePath: require('./assets/29_52_landing.jpeg'), 

    landingVideoPath: undefined, // either landingImagePath or landingVideoPath

    // Landing Page background Image: undefined or image -> require('./assets/<image-path.extension>')
    landingBackroundPath: require('./assets/background.jpeg'),

    // either byImage or byText - Specifies the CLient name through a logo image
    byImage: require('./assets/by_strijdom.png'), 

    // either byImage or byText - Specifies the CLient name through text
    byText: '',

    // text before image on left
    byPreText: 'by',

    // Text that appears above the landing image
    // Keep as empty string to exlude
    textAboveImage: 'This week\'s image',

    // Link Button that appears below the landing image
    linkBelowImage: {
      include: true, // Boolean to indeicate whether to include or not
      link: 'https://www.youtube.com/watch?v=3b1A2Cuouyw&t=3s&ab_channel=FanfireAcademy', // Where to link to
      text: 'About The 52 Collection' // Text for the link
    },

    // Primary Button on Landing Page:
    primary: {
      include: true, // Whether or not to include the button on the landing Page
      text: 'Explore Collection', // Text defined within the button
      routeToPage: 'explore' // Where the button will route to (this must be one of the routeable pages)
    },

    // Secondary Button on Landing Page:
    secondary: {
      include: true, // Whether or not to include the button on the landing Page
      text: 'For Sale', // Text defined within the button
      routeToPage: 'for-sale' // Where the button will route to (this must be one of the routeable pages)
    },

    // Text style for landing page
    styleOverrides: {
      text: {
          // color: 'white'
      }
    }
  },
  /* 
  =================================================================================================================
  === BROWSE COLLECTION PAGE ======================================================================================
  =================================================================================================================
  */
  browseCollectionPage: {
    // Whether to include this page in the App
    include: true, 

    // Header on the explore page
    header: "THE FIFTY TWO COLLECTION", 

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/background.jpeg') 
  },
  /* 
  =================================================================================================================
  === NAV ITEMS ===================================================================================================
  =================================================================================================================
  */
  navItems: {
    navActiveColor: 'white',
    // mobile carousal color of text nav items
    mobileNavItems: {
      styleOverride: {
        text: {
          color: 'white'
        },
        drawerColor: { // Color of the drawer that opens up to nav on the mobile view
          // background: 'blue'
        }
      }
    },
  },
  /* 
  =================================================================================================================
  === PROFILE PAGE ================================================================================================
  =================================================================================================================
  */
  profilePage: {
    // Color of navbar line on active tab (Transactions, my items / liked items)
    navActiveColor: 'white',
    dashboard: {
      styleOverride: {
        text: {
          // color: 'white'
        }
      }
    }
  },
  /* 
  =================================================================================================================
  === NFT DETAIL PAGE =============================================================================================
  =================================================================================================================
  */
  nftDetail:{
    // This option adds a custom description to every NFT detail page - Leave as '' to remove
    generalDescription: '',

    // Custom accordian to add to every Detail Page
    customAccordian:{
      include: false, // Whether or not to include this accordian in detail view
      header: 'Custom Accordian Header', // Header text of the Custom accordian
      items: [
        'item1', // item shown in accoridan dropdown sperated by a divider
        'item2' // item shown in accoridan dropdown sperated by a divider
      ]
    }
  },
  /* 
  =================================================================================================================
  === FOR SALE PAGE ===============================================================================================
  =================================================================================================================
  */
  forSalePage: {
    // Whether to include this page in the App
    include: true, 

    // Header on the for sale page
    header: "THE FIFTY TWO COLLECTION", 

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/background.jpeg') 
  },
  /* 
  =================================================================================================================
  === NFT VAULT TABLE LIST PAGE ===================================================================================
  =================================================================================================================
  */
  nftVaultTable: {
    // Table to display all vault tokens,
    table: {
      /*
      Table is already populated with: Details, Name, Image, Description and a link to Opensea.
      The below options will have to exist within the attributes (properties) of the token metadata. 
      All options here will be displayed as a column within the table.
      */
      attributesFromMeta: ['Vintage', 'Seal Codes', 'Volume (ml)'],
    }
  },
  /* 
  =================================================================================================================
  === FOOTERS =====================================================================================================
  =================================================================================================================
  */
  footers: {
    // Text on mobile permanent footer,
    mobileText: 'strijdom52.co.za',

    footerTextColor: {
      styleOverrides: {
        text: {
            color: 'white'
        },
        socialMediaFooterTextColor: {
          text: {
            // color: 'black'
          },
        }
      }
    }
  },
  /* 
  =================================================================================================================
  === ATTENDANCE TOKENS PAGE ======================================================================================
  =================================================================================================================
  */
  attendanceTokens: {
    // either byImage or byText - Specifies the CLient name through text
    byImage: undefined, 

    // either byImage or byText - Specifies the CLient name through text
    byText: 'Placeholder Client Name', 

    // Background Image of attandance token page
    background: undefined, 

    // Heading of the Attendance Token page
    heading: "Attendance Token Heading", 

    // Sub-heading of the Attendance Token page
    sub_heading: "Attendance Token sub heading", 
  },
}
export default appConfig

/* 
=================================================================================================================
=== FAQ CONFIG ==================================================================================================
=================================================================================================================

Enter All FAQ options in here. This will populate the FAQ Page.
*/
export const faqs = [
  {
    heading: 'About the 52 Collection',
    questions: [
      {
        question: 'Who is Strijdom van der Merwe?',
        answer: `Strijdom van der Merwe is a South African award-winning land artist who uses materials he finds on-site to create his artworks. 
        His materials include sand, water, wood, rocks and stone. Strijdom is also a sculptor and contemporary artist whose art, sculptures, limited edition prints, and art installations form part of art collections, art routes, sculpture parks, and residences internationally. 
        His work makes a statement about our relationship with the land and is informed by an open dialogue with sites and spaces.`
      },
      {
        question: 'What is the Collection?',
        answer: `Strijdom van der Merwe has gained global renown for his land art created with sand, water, wood, rocks, and stone. His work makes a statement about our relationship with the land and is informed by an open dialogue with sites and spaces. 
        With support from a Pollock-Krasner grant, Strijdom has embarked on a project to create a new work of land art each week over an entire year. For the 52 Collection, Strijdom will capture a still image of each installation and for some installations include moving media that documents the creation of the work, and the effects of daylight, weather and time as it transforms.
        The digital originals of these works will be available for purchase at Strijdom52.fanfire.ai from the 31st of March 2022 for 52 weeks.`
      },
      {
        question: 'Why NFTs?',
        answer: `Land art is a difficult artform to share with an audience, since it often involves remote installations that exist only for a brief period. Despite being grounded in nature, Strijdom's art is most often appreciated in print or in digital form – moments captured of a work that is constantly changing with the wind, water and light.
        Each work will be captured as a digital original and non-fungible token (“NFT”), as this binds timelessness to a previously ephemeral piece of art. The full commercial rights to these works (including reprint and online rights) will be available for purchase and ownership will be represented by NFTs. 
        The ownership of the artwork can also be resold at any time, either on the Fanfire platform or platforms that enable the sale of NFTs. A significant advantage of the smart NFT technology, developed by Fanfire for the Strijdom project, is that a resale royalty will always flow back to the artist. The artist essentially obtains an annuity income stream and benefits from the appreciation in the value of their art in future.`
      },
      {
        question: 'Can I print the art work?',
        answer: 'Patrons who own works from the 52 Collection will have full and exclusive rights to the original works, from which they may create their own prints or editions for personal or commercial use. Fanfire partnered with African Sun Media in producing high quality prints on demand.'
      }
    ]
  },
  {
    heading: 'About Fanfire',
    questions: [
      {
        question: 'What does Fanfire do?',
        answer: 'Fanfire provides solutions for artists, athletes and businesses who recognise the opportunities presented by the third-generation web (“Web3”), such as cryptographic tokens, web wallets, and loyalty economies. The team has worked with a diverse range of partners such as the Cell C Sharks rugby franchise and Care for Wild Rhino Sanctuary to create new Web3 engagement strategies, and is also involved in digitizing the ownership and trade of collectible wines.'
      },
      {
        question: 'Who is Fanfire?',
        answer: `Fanfire is part of the Alphawave Group, a leading technology investment group consisting of businesses with products and services that are hard to replicate. The group is headquartered in Stellenbosch and applies South Africa's signature high-innovation-low-cost creativity to concepts with world-wide application. 
        The company is led by the former Research Chair in IoT at Stellenbosch University (who co-founded Custos, an InfoSec blockchain company, in 2013). It is chaired by an industry veteran who has led two JSE-listed companies, and has decades of experience in technology, entertainment and consumer businesses.`
      },
      {
        question: 'What are NFTs?',
        answer: `A non-fungible token or NFT is merely an ownership certificate.
        In much the same way that a title deed represents the ownership of a house, an NFT is a cryptographically-secured digital certificate that proves ownership of something.
        It can represent ownership of anything; such as commercial rights (of a movie or artwork), it could represent ownership of a certain privilege (such as VIP access to a sport stadium or event), or even represent ownership of a physical collectable (such as a wine vintage) — the possibilities are endless. NFTs are validated and secured on blockchains.`
      },
      {
        question: 'What is a blockchain?',
        answer: `In short, a blockchain is a method of recording information such that it is nearly impossible to alter, hack or cheat the system. 
        A blockchain is underpinned by a decentralised (meaning not one institution has custody over it) digital ledger that tracks transactions using secure and trustless protocols. The most famous blockchains include the Bitcoin- and Ethereum-blockchains. 
        Blockchain and cryptocurrencies are not the same. Bitcoin and USDC are two examples of a cryptocurrency, like rands or dollars, that run on a blockchain. These cryptocurrencies are merely a few lines of code (known colloquially as tokens) in a smart contract that gets executed.`
      },
      {
        question: 'What is USDC?',
        answer: 'USD Coin or USDC is a cryptocurrency (like Bitcoin of Ethereum) which is pegged to the United States dollar, making it extremely stable. USDC was launched in 2018 by a consortium of companies called Centre, and is is fully collateralised while being US dollar-backed.'
      },
      {
        question: `What is a 'Web 3 wallet'?`,
        answer: 'A Web3 wallet is essentially a digital wallet which contains all your digital assets, such as NFTs and other coins (or cryptocurrency).'
      },
      {
        question: 'How can I sell my NFT?',
        answer: `On the Fanfire platform you will be able to 'list' your NFTs for sale by clicking the 'List Item' button on your NFT profile page.`
      },
      {
        question: 'How do I redeem my funds?',
        answer: 'Fanfire uses VALR.com, a trusted digital asset trading platform, for users to convert their cryptocurrency into rands. VALR enables the seamless buying, selling, storing and transfer of any cryptocurrency safely and securely in ZAR.'
      },
      {
        question: 'Why does my transaction take so long?',
        answer: `Fanfire's platform is extremely secure and depends on a number of protocols to facilitate a transaction. Some transactions might take longer than others to complete as the blockchain needs to be updated.`
      }
    ]
  }
]

